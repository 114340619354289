<template>
  <div :class="inlineMode == 'true'? 'inlineMode' : 'modifyPassword'">
    <el-card class="modifyPasswordForm" v-if="type == 'active'">
      <div class="title">{{title}}</div>
      <el-form
        ref="activeUserForm"
        :model="activeUserForm"
        :rules="activeUserFormRules"
      >
        <el-form-item prop="userName">
          <el-input
            v-model="activeUserForm.userName"
            :placeholder="$t('modifyPassword.accountActivePla')"
          ></el-input>
        </el-form-item>
        <el-form-item prop="pwd">
          <el-input
            v-model="activeUserForm.pwd"
            :placeholder="$t('modifyPassword.passwordPla')"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item prop="userPwd">
          <el-input
            v-model="activeUserForm.userPwd"
            :placeholder="$t('modifyPassword.confirmPwd')"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        class="sure"
        @click="activeUser"
        :disabled="isValidateEmail"
        >{{ $t('modifyPassword.activateUser') }}</el-button
      >
    </el-card>
    <el-card class="modifyPasswordForm" v-else shadow="none">
      <div v-if="inlineMode=='false'" class="title">{{ title }}</div>
      <el-form
        ref="modifyPasswordForm"
        :model="modifyPasswordForm"
        :rules="modifyPasswordRules"
      >
        <el-form-item
          :prop="oldPassword ? 'password' : ''"
          v-show="oldPassword"
          :label="$t('personnalInfo.password')"
        >
          <el-input
            v-model="modifyPasswordForm.password"
            :placeholder="$t('modifyPassword.psw')"
            show-password
          ></el-input>
          <span class="color1f" v-if="inlineMode=='true'" @click="modify">
              {{ $t('personnalInfo.save') }}
          </span>
        </el-form-item>
        <el-form-item prop="newPassword" label="  ">
            <el-input
              v-model="modifyPasswordForm.newPassword"
              :placeholder="$t('modifyPassword.newPsd')"
              show-password
            ></el-input>
        </el-form-item>
        <el-form-item prop="confirmPassword" label="  ">
            <el-input
              v-model="modifyPasswordForm.confirmPassword"
              :placeholder="$t('modifyPassword.confirmPsd')"
              show-password
            ></el-input>
        </el-form-item>
      </el-form>
      <el-button
          v-if="inlineMode=='false'"
        type="primary"
        class="sure"
        @click="modify"
        :disabled="isValidateEmail"
        >{{ $t('submit') }}</el-button
      >
    </el-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { modifyPassword, validateEmailTimeApi, userActiveApi } from '@/api/app'

export default {
  name: 'ModifyPassword',
  props: {
    inlineMode: {
      type : String,
      default: "false"
    }
  },
  data() {
    // 密码验证
    var pwdCheck = async (rule, value, callback) => {
      let pwdReg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{3,30}$/
      if (value === '') {
        return callback(new Error(this.$t('requireTips')))
      } else if (!pwdReg.test(value)) {
        return callback(new Error(this.$t('modifyPassword.passwordformat')))
      } else {
        callback()
      }
    }
    // 确认新密码不为空
    var pwdAgainCheck = async (rule, value, callback) => {
      if (value.length < 1) {
        this.modifyData = false
        return callback(new Error(this.$t('modifyPassword.newPswRequire')))
      } else if (this.modifyPasswordForm.newPassword != value) {
        this.modifyData = false
        return callback(new Error(this.$t('modifyPassword.pswIsSame')))
      } else {
        callback()
      }
    }

    var isPwdSame = async (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('modifyPassword.newPasswordRule')))
      } else if (this.activeUserForm.userPwd != this.activeUserForm.pwd) {
        return callback(new Error(this.$t('modifyPassword.pswIsSameTipe')))
      } else {
        callback()
      }
    }

    return {
      type: '',
      id: '',
      old: '',
      oldPwd: '',
      oldPassword: true,
      modifyData: true,
      modifyPasswordForm: {
        password: '',
        newPassword: '',
        confirmPassword: '',
      },
      modifyPasswordRules: {
        password: {
          required: true,
          message: this.$t('modifyPassword.psdRequire'),
          trigger: 'blur',
        },
        newPassword: {
          required: true,
          validator: pwdCheck,
          trigger: 'blur',
        },
        confirmPassword: {
          required: true,
          validator: pwdAgainCheck,
          trigger: 'blur',
        },
      },
      activeUserForm: {
        userName: '',
        pwd: '',
        userPwd: '',
      },
      activeUserFormRules: {
        userName: {
          required: true,
          message: this.$t('modifyPassword.userNameRule'),
          trigger: 'blur',
        },
        pwd: {
          required: true,
          validator: pwdCheck,
          trigger: 'blur',
        },
        userPwd: {
          required: true,
          validator: isPwdSame,
          trigger: 'blur',
        },
      },

      isValidateEmail: false,
      validEmailObj: {},
      spanValue: ''
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapState('account', ['loginInfo']),
    title() {
      const obj = {
        reset: this.$t('modifyPassword.resetPsw'),
        active: this.$t('modifyPassword.activateUser'),
      }
      let title = obj[this.type]
        ? obj[this.type]
        : this.$t('modifyPassword.modifyPsw')
      return title
    },
  },
  created() {
    this.type = this.$route.query.type
    this.id = this.$route.query.id
    this.oldPwd = this.$route.query.oldPwd
    this.show()

    this.type == 'reset' ? this.spanValue = '19' : this.spanValue = ''
    if (this.type == 'active' || this.type == 'reset') {
      validateEmailTimeApi({
        randomCode: this.$route.query.randomCode,
        id: this.$route.query.id,
      })
        .then((res) => {
          if (res.data.code === 0) {
            this.validEmailObj =
              (res.data &&
                res.data.result &&
                res.data.result.rows &&
                res.data.result.rows[0]) ||
              {}
          } else {
            this.$router.push('/login')
          }
        })
        .catch(() => {
          this.$router.push('/login')
          this.isValidateEmail = true
        })
    }
  },
  methods: {
    show() {
      if (this.type === 'reset') {
        this.oldPassword = false
        return
      }
    },
    modify() {
      if (this.type === 'reset') {
        this.$refs.modifyPasswordForm.validate((vaild) => {
          if (vaild) {
            modifyPassword({
              id: this.$route.query.id,
              userOldPwd: this.validEmailObj.userPwd,
              userNewPwd: this.modifyPasswordForm.newPassword,
              method: 1,
            })
              .then((res) => {
                this.setIntervalDialog(3, res.data.message)
              })
              .catch(() => {})
          }
        })
      } else {
        this.$refs.modifyPasswordForm.validate((vaild) => {
          if (vaild) {
            let m = {
              id: this.loginInfo.id,
              userNewPwd: this.modifyPasswordForm.newPassword,
              userOldPwd: this.modifyPasswordForm.password,
              method: 2,
            }
            modifyPassword(m)
              .then((res) => {
                this.setIntervalDialog(3, res.data.message)
              })
              .catch(() => {})
          }
        })
      }
    },
    // 激活用户
    activeUser() {
      this.$refs.activeUserForm.validate((valid) => {
        if (valid) {
          userActiveApi({
            id: this.$route.query.id,
            loginName: this.activeUserForm.userName,
            userNewPwd: this.activeUserForm.userPwd,
          })
            .then((res) => {
              this.setIntervalDialog(3, res.data.message)
            })
            .catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 弹窗
    setIntervalDialog(index, msg) {
      var messageBox = this.$message({
        showClose: false,
        dangerouslyUseHTMLString: true,
        message: `<p>${msg}</p>${this.$t('modifyPassword.loginSkipTips', [
          index,
        ])}`,
        type: 'success',
        duration: 5000,
      })
      //倒计时函数
      let msgBInterval = setInterval(async () => {
        if (index == 0) {
          //5s结束后的操作
          clearInterval(msgBInterval)
          await this.$store.dispatch('account/LoginOut')
          this.$router.replace('/login')
        } else {
          messageBox.message = `<p>${msg}</p>${this.$t(
            'modifyPassword.loginSkipTips',
            [index]
          )}`
          index--
        }
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.inlineMode {
  .el-card {
    border-width: 0;

    .el-form-item{
      margin-left: -28px;

    }
  }
}

.color1f {
  color: #1f7ff5;
  float: right;
  cursor: pointer;
}

.modifyPassword {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('../../assets/images/emailmodifrbg.jpg') no-repeat center
    center / 100% auto;
  margin: 0;
  .modifyPasswordForm {
    height: 403px;
    width: 610px;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
    box-sizing: border-box;
    .title {
      color: #383038;
      width: 100%;
      font-size: 24px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 38px;
    }
    ::v-deep.el-form-item {
      margin-bottom: 30px;
    }
    ::v-deep.el-form-item__content {
      width: 400px;
      margin: 0 auto;
    }
    ::v-deep.el-card__body {
      padding-top: 18px;
    }
    .sure {
      width: 400px;
      margin-left: 86px;
      margin-top: 2px;
    }
    .back {
      display: block;
      text-decoration: none;
      width: 100px;
      margin: 10px auto;
    }
  }
  .el-form{
    margin-left: 10%;
  }
}
</style>
